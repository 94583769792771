.landing-page {
  display: flex; 
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #f0f0f0;
  padding: 20px;
}


.logo {
  max-width: 40%;
  height: auto;
  margin-bottom: 20px;
}

.summary {
  font-size: 18px;
  color: #333;
  max-width: 90%;
  margin-bottom: 20px;
}

.twitch-embed {
  display: flex; 
  justify-content: center;
  align-items: flex-start;
  margin-top: 20px;
  flex-wrap: wrap;
}

.twitch-stream,
.twitch-chat {
  display: flex; 
  justify-content: center;
  align-items: center;
}

/* Default sizes for desktop */
.twitch-stream iframe {
  width: 854px;
  height: 480px;
}

.twitch-chat iframe {
  width: 350px;
  height: 480px;
}

/* Media query for screens smaller than 1024px */
@media (max-width: 1024px) {
  .twitch-stream iframe {
    width: 640px;
    height: 360px;
  }

  .twitch-chat iframe {
    width: 300px;
    height: 360px;
  }
}

/* Media query for screens smaller than 770px */
@media (max-width: 770px) {
  .twitch-embed {
    flex-direction: column;
    align-items: center;
  }

  .twitch-stream iframe,
  .twitch-chat iframe {
    width: 100%; /* Responsive width */
    height: auto;
  }

  .twitch-stream iframe,
  .twitch-chat iframe {
    height: 500px;
  }

  .twitch-stream,
  .twitch-chat {
    width: 100%;
    justify-content: center;
    
  }
}

/* Media query for screens smaller than 480px */
@media (max-width: 480px) {
  .summary {
    font-size: 16px;
    
  }

  .twitch-stream iframe,
  .twitch-chat iframe {
    display: flex;
    width: 100%;
    height: 500px;
   
  }

  
}
